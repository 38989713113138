.modal-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  justify-content: center;
}

/* Main Content */
.modal-preview-media {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 300px;
  max-width: 318px;
  border-radius: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff;
  overflow: hidden;
}

@media (max-width: 376px) {
  .modal-preview-media {
    width: 300px;
    max-height: 380px;
  }
}

/* Footer Container */
.modal-preview-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-top: 20px;
}

.report-button {
  background: none;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 20px;
}
