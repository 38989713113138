.empty-description {
  text-align: center;
  max-width: 320px;
  font-size: 16px;
  font-weight: 860;
  line-height: 20px;
}

.empty-button {
  background-color: white;
  width: 80px;
  height: 80px;
  margin-top: 20px;
}

.empty-button-image {
  width: 100%;
  height: 100%;
}
