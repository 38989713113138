.item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  height: 74px;
}

.item-left {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.item-username {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: lowercase;
  text-align: left;
}

.item-media-type {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: lowercase;
  text-align: left;
}

.item-date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: lowercase;
  text-align: right;
}

.item-time {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: lowercase;
  text-align: right;
}
