.bulletin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.bulletin-icon {
  font-size: 80px;
  margin: 0;
}

.bulletin-title {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #00080D;
}

.bulletin-description {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  max-width: 70%;
  height: auto;
  font-size: 16px;
  text-align: center;
  color: #000;
}

.bulletin-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.bulletin-confirm-action {
  margin-top: 8px;
  align-self: center;
}

.bulletin-cancel-action {
  margin-top: 8px;
  align-self: center;
}
