.response-button {
  width: 60px;
  height: 60px;
  background: none;
  border: none;
}

.response-button-image {
  width: 44px;
  height: 44px;
  object-fit: cover;
  background-color: transparent;
}
