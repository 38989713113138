.empty-prize-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.empty-prize-content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: auto;
  align-items: center;
  width: 100%;
  max-width: 300px;
}

.empty-prize-subtitle {
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #00080D33;
  text-align: center;
}

.empty-prize-title {
  font-family: 'Avenir Next', sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #FE0000;
  text-align: center;
}

.empty-prize-icon {
  width: 200px;
  height: 200px;
  margin: 0;
  margin-top: 40px;
  border-radius: 100px;
}

.empty-prize-actions {
  margin: auto;
  margin-top: 60px;
}
