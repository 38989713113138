.stack-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.optin-button-container {
  position: absolute;
  width: 80px;
  height: 80px;
  right: 0;
  margin-right: 40px;
  bottom: 0;
  margin-bottom: 20px;
  background-color: red;
}

.optin-button {
  width: 100%;
  height: 100%;
}
